.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #22272e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 
MY STYLES */

body {
  background-color: #22272e;
}

.span {
  padding-top: 7px;
  padding-bottom: 2%;
}

.td-image {
  max-width: 196px;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.scriptContainer {
  margin: 1% 25% 1% 25%;
}

.center {
  text-align: center;
}

.white-color {
  color: white;
}

span {
  margin-bottom: 2%;
  margin-top: 1%;
}

.flex {
  display: flex;
}

.flextbl {
  flex-direction: column;
}

table {
  padding-top: 6%;
}

th {
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

td {
  padding-top: 1%;
  padding-bottom: 3%;
}

.thtdSizeForDynamicTable { 
  width: 474.4px; 
}

div h2 ~ table tbody tr td:nth-child(2) {
  width: 8rem;
}

div h2 ~ table tbody tr td:nth-child(3) {
  width: 11rem;
}

.table td {
  vertical-align: middle;
}

.margintbl {
  margin-top: 50%;
}

a.scriptLink {
  text-decoration: none;
  color: #6CBAC6
}

a.scriptLink:visited {
  color: #6CBAC6;
}

a.scriptLink:hover {
  color: #C6786C;
}

.filenameActivity {
  color: #6CBAC6;
}

.filenameActivity:hover {
  color: #C6786C;
  cursor: pointer;
}

.dynamtable {
  margin: auto;
  width: 70%;
}

.footer {
  color: white;
  margin-bottom: 1%;
  border-top: 1px solid white;
}
.footermargin {
  margin-top: 1%;
}

.margin-top {
  margin-top: 12%;
}

.smallFont {
  font-size: 77%;
}

.ptext {
  font-size: 58%;
  margin-top: 1%;
}

.litext {
  font-size: 83%;
  margin-top: 2%;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.scene {
  font-size: 200%;
}

.space {
  width: 400px;
}

.searchBarAdjust {
  padding-top: 2.2rem;
  padding-left: 15px;
}

.onClickTable { 
  width: 600px;
}

.selectClass {
    display: block;
    height: calc(1.5em + .75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/* MEDIA QUERIES */

@media (max-width: 500px) {
  .App-header {
    font-size: calc(8px + 2vmin);
  }

  .App-logo {
    height: 30vmin;
  }

  .container {
    flex-direction: column;
    padding: 10px;
  }

  .scriptContainer {
    margin: 2% 5% 2% 5%;
  }

  .td-image {
    max-width: 100px;
  }

  .dynamtable {
    width: 90%;
  }

  .margintbl {
    margin-top: 25%;
  }

  .smallFont {
    font-size: 0.7rem;
  }

  .ptext {
    font-size: 0.6rem;
  }

  .litext {
    font-size: 0.75rem;
  }

  .footer {
    font-size: 0.8rem;
  }

  .footermargin {
    margin-top: 2%;
  }

  .center {
    width: 90%;
    padding: 5px;
  }

  .scene {
    font-size: 1.5rem;
  }

  .space {
    width: 100%;
  }

  table {
    padding-top: 3%;
    font-size: .8rem;
  }

  th, td {
    padding: 5px;
  }
  
  div h2 ~ table tbody tr td:nth-child(2) {
    width: 0rem;
  }

}

@media (max-height: 900px) and (orientation: portrait) {
  h1 {
    font-size: 2.5rem;
  }
  
  .smallFont {
    font-size: 0.6rem;
  }

  .ptext {
    font-size: 0.8rem;
  }

  .footermargin {
    font-size: 0.7rem;
  }

  .dynamtable {
    width: 90%;
  }

  div h2 ~ table tbody tr td:nth-child(2) {
    width: 0rem;
  }
}
/* a {
  color: #575757;
}

a:visited {
  color: #575757;
} */

/*
!MY STYLES */